<script>
import qs from "qs";
import { account_balance } from "@/api/beijing28";
import {
  cloudBaseUrl,
  getClientTime,
  headerMenu,
  TEMPLATE2_headerMenu,
  lastDrawingRule,
  TEMPLATE1_titleMenu,
  TEMPLATE2_titleMenu,
  TEMPLATE3_titleMenu,
  TEMPLATE3_headerMenu,
} from "@/CONF.js";
export default {
  name: "Header",
  data() {
    return {
      currentLanguage: window.language,
      languages: window.languageList,
      languageListToggle: false,
      open: {
        headerMenu: false,
        titleMenu: false,
      },
      lhcType: null,
      signNav: {
        in: {
          path: "login",
          query: {
            sign: "login",
          },
        },
        up: {
          path: "login",
          query: {
            sign: "reg",
          },
        },
        show: false,
      },

      /* template-2 begin */
      user: {
        username: null,
        balance: 0,
      },
      TEMPLATE2_componentDockerToggle: false,
      TEMPLATE2_auctioneerUIToggle: false,

      /* template-2 end */
    };
  },
  props: [
    "mainTitle",
    "mainTitleId",
    "subTitle",
    "headerMenu",
    "titleMenu",
    "game",
    "gameType",
    "gameName",
    "backButton",
    "toggleTitleMenuClass",
    "currentComponentIndex",
    /* template-2 begin */
    "logoUrl",
    "serviceIcon",
    "toggleComponentDocker",
    "backHome",
    "backGameList",
    "backClose",
    "backTo",
    "hideInfo",
    /* template-3 begin */
    "toggleDrawer",
    "signNavTag",
    "auctioneerUI",
    "chatroomOpen",
    "transparent",
    "hide",
  ],
  inject: ["toggleChatRoom"],
  methods: {
    checkType2() {
      if (!this.gameType) {
        return false;
      }
      var _ = this.gameType;

      var wzwn = [
        "wanneng",
        "damacai",
        "duoduo",
        "yuedawan",
        "shaba88",
        "saimahui",
        "xinjapowz",
      ];

      if (_.indexOf("28") > -1 && _ !== "pc28one") {
        return true;
      }

      if (wzwn.indexOf(_) > -1) {
        return true;
      }
    },
    changeUILanguage(event) {
      console.log(event);
    },
    toggle(menu) {
      if (
        _TEMPLATE === "template-2" &&
        this.gameType.indexOf("28") === -1 &&
        this.gameType !== "wanneng" &&
        this.gameType !== "damacai" &&
        this.gameType !== "duoduo" &&
        this.gameType !== "yuedawan" &&
        this.gameType !== "shaba88" &&
        this.gameType !== "saimahui" &&
        this.gameType !== "xinjapowz" &&
        this.$route.name !== "room_list"
      ) {
        this.redirectPath("/GameList");
        return false;
      }
      if (this._TEMPLATE !== "template-2") {
        if (
          (menu === "titleMenu" &&
            this.titleMenu &&
            this.titleMenu.length === 0) ||
          (menu === "titleMenu" && this.currentComponentIndex !== 0)
        )
          return false;
      } else {
        if (
          menu === "titleMenu" &&
          this.titleMenu &&
          this.titleMenu.length === 0
        )
          return false;
      }
      var that = this;
      function reset() {
        for (var key in that.open) {
          that.open[key] = false;
        }
      }
      if (this.open[menu] === true) {
        reset();
      } else {
        reset();
        if (!menu) {
        } else {
          this.open[menu] = !this.open[menu];
        }
      }
    },
    back() {
      if (this.hide) {
        this.$root.$emit("hideRule", false);
        return false;
      }
      var search = qs.parse(window.location.search.substring(1));
      var query = qs.parse(search._search);
      if (
        (_TEMPLATE === "template-3" &&
          localStorage.getItem("game") &&
          !JSON.parse(localStorage.getItem("game")).room &&
          location.href.indexOf("/t") > -1) ||
        location.href.indexOf("/beijing28/room_list") > -1
      ) {
        this.$router.push({
          path: "/home",
        });
        return false;
      }
      if (this.backClose) {
        this.toggleChatRoom(true);
        return false;
      }
      if (this.backTo) {
        this.$router.push({
          path: this.backTo,
        });
        return false;
      }
      this.backHome
        ? this.$router.push({
            path: "/home",
          })
        : this.backGameList
        ? this.$router.push({
            path: "/GameList",
          })
        : search._pathname
        ? this.$router.push({
            path: search._pathname,
            query: query,
          })
        : this.$router.go(-1);
    },
    changeType(menu, item, itemType) {
      this.titleMenuSwitcher({
        type: this.$route.query.type,
        class: item.title,
        id: item.id,
        classType: itemType,
        alias: item.alias,
        classTypeId: item.parentId,
      });

      this.toggle("titleMenu");
    },
    invokeFrom(data) {
      this.toggle("headerMenu");
      this[data]();
    },
    linkTo(item) {
      this.headerMenuProcessor(item);
      this.toggle("headerMenu");
    },
    redirectPath(path, query) {
      this._linkTo({
        path: path,
        query: query,
      });
    },
    restore() {
      if (this.TEMPLATE2_componentDockerToggle === true) {
        this.TEMPLATE2_componentDockerToggle = false;
        this.toggleComponentDocker();
      }
    },
    showTypes(item) {
      this.titleMenu.forEach((item) => {
        item.active = false;
      });
      item.active = true;
    },
    toggleDropdown(key) {
      this[key] = !this[key];
    },
    getFlagUrl() {
      var related = this.languages.filter((item) => {
        return this.currentLanguage === item.lang_code;
      })[0].icon;
      return related;
    },
    changeUILanguage(language) {
      window.sessionStorage.setItem("currentLanguage", language);
      window.location.href = window.location.href;
    },
  },
  mounted() {
    var that = this;
    this.$root.$on("titleMenuSwitcher", (data) => {
      if (!that.toggleTitleMenuClass) return false;
      if (data.close === true) {
        that.toggleTitleMenuClass();
      } else {
        that.toggleTitleMenuClass(data);
        this.lhcType = this.game.info.currentAuctioneerUIClass.class;
      }
    });

    this.$root.$on("TEMPLATE2_toggleComponentDocker", (data) => {
      this.TEMPLATE2_componentDockerToggle = data;
    });
    this.$root.$on("TEMPLATE2_toggleAuctioneerUI", (data) => {
      this.TEMPLATE2_auctioneerUIToggle = data;
    });
  },
  computed: {
    userBalance() {
      return (
        this.$store.state.userBalance &&
        this.$store.state.userBalance["user_money"]
      );
    },
    chatroomSettings() {
      return this.$store.state.chatroomSettings;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    checkOpen() {
      var open = null;
      for (var key in this.open) {
        if (this.open[key] === true) {
          open = true;
          break;
        }
      }
      if (open === true) {
        return true;
      } else {
        return false;
      }
    },
    fetchedHeaderMenu() {
       console.log(this.headerMenu, TEMPLATE3_titleMenu());
      if (this._TEMPLATE === "template-2") {
        switch (this.gameType) {
          case "lhc":
            return this.headerMenu.lhc;
          case "lhcaomen":
            return this.headerMenu.lhcaomen;
          case "lhcone":
            return this.headerMenu.lhcone;
          case "lhcthree":
            return this.headerMenu.lhcthree;
          case "lhcfive":
            return this.headerMenu.lhcfive;
          case "lhcten":
            return this.headerMenu.lhcten;
          case "tencent":
            return this.headerMenu.tencent;
          case "hljssc":
            return this.headerMenu.hljssc;
          case "tjssc":
            return this.headerMenu.tjssc;
          case "sscone":
            return this.headerMenu.sscone;
          case "sscthree":
            return this.headerMenu.sscthree;
          case "sscfive":
            return this.headerMenu.sscfive;
          case "scone":
            return this.headerMenu.scone;
          case "scthree":
            return this.headerMenu.scthree;
          case "scfive":
            return this.headerMenu.scfive;
          case "fastthreeone":
            return this.headerMenu.fastthreeone;
          case "fastthreethree":
            return this.headerMenu.fastthreethree;
          case "fastthreefive":
            return this.headerMenu.fastthreefive;
          case "fastthreeten":
            return this.headerMenu.fastthreeten;
          case "bj28":
          case "jnd28":
          case "twbg28":
          case "mgelg28":
          case "jndx28":
          case "snfk28":
            return this.headerMenu.pc28;
          case "pc28one":
            return this.headerMenu.pc28one;
          case "shfastthree":
            return this.headerMenu.shfastthree;
          case "jsfastthree":
            return this.headerMenu.jsfastthree;
          case "hbfastthree":
            return this.headerMenu.hbfastthree;
          case "gdfiveselect":
            return this.headerMenu.gdfiveselect;
          case "sdfiveselect":
            return this.headerMenu.sdfiveselect;
          case "jsfiveselect":
            return this.headerMenu.jsfiveselect;
          case "jxfiveselect":
            return this.headerMenu.jxfiveselect;
          case "shhfiveselect":
            return this.headerMenu.shhfiveselect;
          case "anhfiveselect":
            return this.headerMenu.anhfiveselect;
          case "shxfiveselect":
            return this.headerMenu.shxfiveselect;
          case "fc3d":
            return this.headerMenu.fc3d;
          case "gxklsf":
            return this.headerMenu.gxklsf;
          case "gdklten":
            return this.headerMenu.gdklten;
          case "jndxy":
            return this.headerMenu.jndxy;
          case "cqklten":
            return this.headerMenu.cqklten;
          case "wanneng":
            return this.headerMenu.wanneng;
          case "damacai":
            return this.headerMenu.damacai;
          case "duoduo":
            return this.headerMenu.duoduo;
          case "yuedawan":
            return this.headerMenu.yuedawan;
          case "shaba88":
            return this.headerMenu.shaba88;
          case "saimahui":
            return this.headerMenu.saimahui;
          case "xinjapowz":
            return this.headerMenu.xinjapowz;
          case "tiantiancai":
            return this.headerMenu.tiantiancai;
          case "azxy5":
            return this.headerMenu.azxy5;
          case "azxy10":
            return this.headerMenu.azxy10;
          default:
            return [];
        }
      }
      if (this._TEMPLATE === "template-1"||this._TEMPLATE === "template-3") {
        switch (this.gameType) {
          case "bj28":
          case "jnd28":
          case "twbg28":
          case "mgelg28":
          case "jndx28":
          case "snfk28":
            return this.headerMenu.level1;
          case "bjsc":
          case "xyft":
          case "cqssc":
            return this.headerMenu.level2;
          case "pc28one":
          case "tencent":
          case "fc3d":
          case "jndxy":
          case "azxy5":
          case "azxy10":
          case "lhc":
          case "lhcaomen":
          case "lhcone":
          case "lhcthree":
          case "lhcfive":
          case "lhcten":
          case "scone":
          case "scthree":
          case "scfive":
          case "sscone":
          case "sscthree":
          case "sscfive":
          case "hljssc":
          case "tjssc":
          case "fastthreeone":
          case "fastthreethree":
          case "fastthreefive":
          case "fastthreeten":
          case "shfastthree":
          case "jsfastthree":
          case "hbfastthree":
          case "gdfiveselect":
          case "sdfiveselect":
          case "jsfiveselect":
          case "jxfiveselect":
          case "shhfiveselect":
          case "anhfiveselect":
          case "shxfiveselect":
          case "gxklsf":
          case "gdklten":
          case "cqklten":
          case "tiantiancai":
            return this.headerMenu.level3;
          case "wanneng":
          case "damacai":
          case "duoduo":
          case "yuedawan":
          case "shaba88":
          case "saimahui":
          case "xinjapowz":
            return this.headerMenu.level4;
          default:
            return [];
        }
      }
    },
    _userInfo() {
      return this.$store.state.userInfo;
    },
    onlineUsers() {
      return this.$store.state.onlineUsers;
    },
    chatroomSettings() {
      return this.$store.state.chatroomSettings;
    },
  },
};
</script>
<template>
  <header :class="_TEMPLATE">
    <template v-if="_TEMPLATE!=='template-3'">
      <div
        class="inner"
        @click="restore"
        :class="{
                'covered':TEMPLATE2_componentDockerToggle||(TEMPLATE2_auctioneerUIToggle&&gameType.indexOf('28')>-1),
                'transparent':transparent,
            }"
      >
        <div class="left">
          <button
            id="history-back"
            @click="back"
            v-if="!backButton"
          >
            <i class="iconfont icon-back"></i>{{ getWord('back_to_previous') }}
          </button>
          <div
            class="dropdown i18n"
            v-if="logoUrl || signNavTag"
          >
            <h4
              class="current"
              @click='toggleDropdown("languageListToggle")'
            >
              <img :src="getFlagUrl()" />
              {{ currentLanguage }}
              <i class="iconfont icon-back"></i>
            </h4>
            <ul v-if="languageListToggle">
              <li
                v-for="item in languages"
                @click="changeUILanguage(item.lang_code)"
              ><img :src="item.icon" />{{ item.name }}</li>
            </ul>
          </div>
        </div>

        <h1
          class="logo"
          v-if="logoUrl || signNavTag"
        >

          <span>
            <img
              :src="logoUrl"
              v-if="logoUrl"
            />
          </span>

        </h1>

        <h1 v-if="!logoUrl && !signNavTag">
          {{mainTitle}}
          <template v-if="_TEMPLATE!=='template-3'">
            <i
              @click="toggle('titleMenu')"
              v-if="gameType!=='wanneng' && gameType!=='damacai' && gameType!=='duoduo' && gameType!=='yuedawan' && gameType!=='shaba88' && gameType!=='saimahui' && gameType!=='xinjapowz' && this.$route.name==='home'"
              class="iconfont icon-back"
              id="title-menu-switcher"
              :class="{ active:open.titleMenu }"
            ></i>
          </template>
          <template v-else>
            <i
              v-if="gameType!=='wanneng' && gameType!=='damacai' && gameType!=='duoduo' && gameType!=='yuedawan' && gameType!=='shaba88' && gameType!=='saimahui' && gameType!=='xinjapowz' && titleMenu && titleMenu.length!==0 && currentComponentIndex===0"
              class="iconfont icon-back"
              id="title-menu-switcher"
              :class="{ active:open.titleMenu }"
            ></i>
          </template>
          <span
            class="sub-title"
            v-show="subTitle || lhcType!==null"
          >
            {{subTitle?subTitle:lhcType}}
          </span>
        </h1>

        <div
          class="right"
          :class="{ 'expand':!userInfo && (logoUrl || signNavTag) }"
        >

          <a
            v-if="serviceIcon&&checkType2()"
            id="service"
            @click='redirectPath("/customer")'
          ><i class="iconfont icon-service2"></i></a>

          <nav
            class="sign"
            v-if="!userInfo && (logoUrl || signNavTag)"
          >
            <a @click="redirectPath(signNav.up.path,signNav.up.query)">{{ getWord('sign_up') }}</a>
            <a @click="redirectPath(signNav.in.path,signNav.in.query)">{{ getWord('sign_in') }}</a>
          </nav>

          <nav
            class="header-menu"
            v-if="fetchedHeaderMenu&&fetchedHeaderMenu.length!==0"
          >
            <i
              class="iconfont icon-menu ripple"
              @click="toggle('headerMenu')"
              id="header-menu-switcher"
            ></i>
            <transition name="fade">
              <ul v-show="open.headerMenu">
                <li v-for="item in fetchedHeaderMenu">
                  <a
                    v-if="item.linkTo"
                    @click="redirectPath(item.linkTo)"
                  >
                    <i
                      class="iconfont"
                      :class="item.icon"
                    ></i>
                    {{item.title}}
                  </a>
                  <a
                    v-if="!item.linkTo"
                    @click="linkTo(item)"
                  >
                    <i
                      class="iconfont"
                      :class="item.icon"
                    ></i>
                    {{item.title}}
                  </a>
                </li>
              </ul>
            </transition>
          </nav>
          <!-- bug #1575 -->
          <hgroup v-if="userInfo && serviceIcon && !checkType2()">
            <h6><i class="iconfont icon-personal"></i>{{userInfo.nickname}}</h6>
            <h6><i class="iconfont2 icon2-coins"></i>{{userBalance}}</h6>
          </hgroup>

        </div>
      </div>

      <transition name="slide-fade">
        <nav
          class="title-menu"
          v-if="titleMenu"
          v-show="open.titleMenu"
        >
          <template v-for="entries in titleMenu">
            <h5>{{entries.type}}</h5>
            <ul class="clearfix">
              <li
                v-for="item in entries.list"
                :class="{ highlight:item.active }"
              >
                <a @click="redirectPath(item.linkTo)">
                  {{item.title}}
                </a>
              </li>
            </ul>
          </template>
        </nav>
      </transition>

      <div
        class="viewport-overlay"
        :class="{ active:checkOpen }"
        @click.stop="toggle()"
      ></div>
    </template>

    <template v-if="_TEMPLATE==='template-3'">
      <div
        class="inner"
        :class="{'transparent':transparent}"
      >

        <div
          class="left"
          :class="{ 'collapse':toggleDrawer }"
        >
          <button
            id="history-back"
            @click="back"
            v-if="!backButton"
          >
            <i class="iconfont icon-back"></i>
            <span>{{ getWord('back_to_previous') }}</span>
          </button>
          <i
            id="side-menu-toggle"
            class="iconfont icon-side-menu"
            @click="toggleDrawer"
            v-if="toggleDrawer"
          ></i>
        </div>

        <h1
          @click="toggle('titleMenu')"
          :class="{'has-drawer':toggleDrawer}"
        >

          <span class="main-title">
            <template v-if="game && game.info.currentAuctioneerUIClass && auctioneerUI">
              {{ getWord('game_rules2')+'：'+game.info.currentAuctioneerUIClass.class}}
            </template>
            <template v-else>
              {{mainTitle}}
            </template>
          </span>
          <i
            v-if="titleMenu && titleMenu.length!==0 && currentComponentIndex===0 && auctioneerUI"
            class="iconfont icon-back"
            id="title-menu-switcher"
            :class="{ active:open.titleMenu }"
          ></i>
          <span
            class="sub-title"
            v-show="(subTitle&&!auctioneerUI) || lhcType!==null && ((gameType.indexOf('28')>-1||gameType==='cqssc'||gameType==='bjsc'||gameType==='xyft') &&!auctioneerUI) "
          >
            {{subTitle?subTitle:lhcType}}
          </span>
          <span
            class="sub-title"
            v-show="onlineUsers&&chatroomOpen&&chatroomSettings&&chatroomSettings.count_display==='1'"
          >
            {{onlineUsers||1}} {{ getWord('people') }}
          </span>
        </h1>

        <div
          class="dropdown i18n"
          v-if="toggleDrawer"
        >
          <h4
            class="current"
            @click='toggleDropdown("languageListToggle")'
          >
            <img :src="getFlagUrl()" />
            {{ currentLanguage }}
            <i class="iconfont icon-back"></i>
          </h4>
          <ul v-if="languageListToggle">
            <li
              v-for="item in languages"
              @click="changeUILanguage(item.lang_code)"
            ><img :src="item.icon" />{{ item.name }}</li>
          </ul>
        </div>

        <div
          class="right"
          :class="{ 'expand':!toggleDrawer }"
        >
          <nav
            class="sign"
            v-if="signNavTag"
          >

            <template v-if="!userInfo">
              <i class="iconfont icon-person"></i>
              <a @click="redirectPath(signNav.up.path,signNav.up.query)">{{ getWord('sign_up') }}</a>
              <a @click="redirectPath(signNav.in.path,signNav.in.query)">{{ getWord('sign_in') }}</a>
            </template>

            <template v-else>
              <!-- <img class="avatar" v-if="_userInfo" :src="_userInfo.headimg" /> -->
              <i class="iconfont icon-person"></i>
              <img
                class="icon-gold"
                :src="require('@@/assets/images/home/icons/gold.png')"
              />
              <span>{{userBalance}}</span>
            </template>
          </nav>

          <template v-if="_TEMPLATE==='template-1'||_TEMPLATE==='template-2'">
            <a
              v-if="serviceIcon"
              id="service"
              @click='redirectPath("/customer")'
            ><i class="iconfont icon-service2"></i></a>
          </template>
          <template v-if="_TEMPLATE==='template-3'">
            <a
              v-if="serviceIcon&&mainTitleId!=='2'"
              id="service"
              @click='redirectPath("/customer")'
            ><i class="iconfont icon-service2"></i></a>
          </template>

          <nav
            class="header-menu"
            v-if="fetchedHeaderMenu.length!==0"
          >
            <i
              class="iconfont icon-shezhi ripple"
              @click="toggle('headerMenu')"
              id="header-menu-switcher"
            ><b>{{ getWord('menu2') }}</b></i>
            <transition name="fade">
              <ul v-show="open.headerMenu">
                <li
                  v-for="item in fetchedHeaderMenu"
                  :tag="item.tag"
                >
                  <template v-if="item.tag!=='chatroom'">
                    <a
                      v-if="item.linkTo"
                      @click="redirectPath(item.linkTo)"
                    >
                      <i
                        class="iconfont"
                        :class="item.icon"
                      ></i>
                      {{item.title}}
                    </a>
                    <a
                      v-if="item.eventData"
                      @click="linkTo(item)"
                    >
                      <i
                        class="iconfont"
                        :class="item.icon"
                      ></i>
                      {{item.title}}
                    </a>
                    <a
                      v-if="item.invokeFunc"
                      @click="invokeFrom(item.invokeFunc)"
                    >
                      <i
                        class="iconfont"
                        :class="item.icon"
                      ></i>
                      {{item.title}}
                    </a>
                  </template>
                  <template v-if="chatroomSettings&&item.tag==='chatroom'&&chatroomSettings.inlet_display==='1'">
                    <a
                      v-if="item.linkTo"
                      @click="redirectPath(item.linkTo)"
                    >
                      <i
                        class="iconfont"
                        :class="item.icon"
                      ></i>
                      {{item.title}}
                    </a>
                    <a
                      v-if="item.eventData"
                      @click="linkTo(item)"
                    >
                      <i
                        class="iconfont"
                        :class="item.icon"
                      ></i>
                      {{item.title}}
                    </a>
                    <a
                      v-if="item.invokeFunc"
                      @click="invokeFrom(item.invokeFunc)"
                    >
                      <i
                        class="iconfont"
                        :class="item.icon"
                      ></i>
                      {{item.title}}
                    </a>
                  </template>
                </li>
              </ul>
            </transition>
          </nav>

          <slot name="right-menu">

          </slot>
        </div>
      </div>

      <transition name="slide-fade">
        <nav
          class="title-menu"
          v-if="titleMenu"
          v-show="open.titleMenu"
          :class="{ 
                        fiveselect:gameType.indexOf('fiveselect')>-1, 
                        ffc:gameType.indexOf('tencent')>-1,
                        klten:gameType.indexOf('klten')>-1,
                        gxklsf:gameType.indexOf('gxklsf')>-1,
                    }"
        >
          <template v-for="entries in titleMenu">
            <h5
              :class="{ active:entries.active }"
              @click="showTypes(entries)"
            >{{entries.type}}</h5>
            <ul class="clearfix">
              <li
                v-for="item in entries.list"
                :class="{ highlight:item.active }"
              >
                <a @click="changeType(titleMenu,item,entries.type)">
                  <template v-if="_TEMPLATE==='template-3'&&gameType.indexOf('fiveselect')>-1&&entries.id==='3'">
                    {{item.title.replace('连码','')}}
                  </template>
                  <template v-else-if="_TEMPLATE==='template-3'&&gameType.indexOf('fc3d')>-1&&entries.id==='5'">
                    {{item.title.replace('二字定位','').split('位')[0]+'定位'}}
                  </template>
                  <template v-else>
                    {{item.title}}
                  </template>
                </a>
              </li>
            </ul>
          </template>
        </nav>
      </transition>

      <div
        class="viewport-overlay"
        :class="{ active:checkOpen }"
        @click.stop="toggle()"
      ></div>
    </template>
  </header>
</template>
<style lang='scss' scoped>
@import "@@/assets/style/_variables";
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

header {
  color: #ffffff;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, “Roboto”, “Oxygen”,
    “Ubuntu”, “Cantarell”, “Fira Sans”, “Droid Sans”, “Helvetica Neue”,
    sans-serif;

  &.template-3 {
    .inner {
      background-color: #ec2829;

      &.transparent {
        background-color: transparent;
      }

      .left {
        #history-back {
          position: static;
          display: flex;
          align-items: center;

          i,
          span {
            vertical-align: baseline;
            line-height: 1;
          }
        }
      }

      .right {
        nav {
          &.header-menu {
            #header-menu-switcher {
              font-size: 0.33rem;
              white-space: nowrap;
              margin-right: 0.15rem;

              b {
                margin-left: 0.05rem;
                font-weight: normal;
                font-size: 0.32rem;
                line-height: 1;
              }
            }

            ul {
              li {
                a {
                  i {
                    color: #ec2829;
                  }
                }
              }
            }
          }

          &.title-menu {
            padding: 0;
            background-color: #eeeeee;
            min-height: 45vh;

            // &.klten {
            //     min-height: 3.5rem;
            // }

            &.fiveselect {
              min-height: 65vh;
            }

            // &.ffc {
            //     min-height: 1.25rem;
            // }

            // &.gxklsf {
            //     min-height: 3rem;
            // }

            h5 {
              color: #5f646e;
              font-size: 0.3rem;
              font-weight: normal;
              width: 2rem;
              text-align: center;
              padding: 0.1rem 0;
              border-bottom: 1px solid #cccccc;
              border-right: 1px solid rgba(0, 0, 0, 0.1);
              transform: translateX(-1px);

              &:first-child {
                border-top: 1px solid #cccccc;
              }

              &.active {
                color: #ec2829;
                border-right: 0;
              }

              &.active ~ h5 {
                border-right: 0;
              }

              &.active + h5 {
                border-right: 0;
              }

              &.active + ul {
                display: block;
              }
            }

            ul {
              display: none;
              position: absolute;
              left: 2rem;
              top: 0;
              bottom: 0;
              right: 0;
              box-shadow: -1px 0px 0px 0px rgba(0, 0, 0, 0.1);
              padding: 0.25rem;
              margin-top: 0;

              li {
                width: auto;

                &.highlight {
                  a {
                    color: #ec2829;
                    border-color: #ec2829;
                  }
                }

                a {
                  background-color: #ffffff;
                  display: inline-block;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }

    .icon-side-menu {
      font-size: 0.5rem;
      vertical-align: middle;
      position: relative;
      top: 0.033rem;
    }

    h1 {
      &.has-drawer {
        text-align: left;
        padding-left: 0.1rem;
        width: 60%;

        .main-title {
          vertical-align: middle;
        }
      }
    }

    .dropdown {
      position: relative;
      margin-right: 0.15rem;

      h4 {
        font-weight: normal;
        font-size: 0.24rem;
        text-transform: uppercase;
        display: flex;

        img {
          height: 0.25rem;
        }

        .icon-back {
          font-size: 0.22rem;
          display: inline-block;
          transform: rotateZ(270deg);
        }
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        list-style: none;
        min-width: 4rem;
        background-color: #ffffff;
        color: #333333;
        border-radius: 0.1rem;
        margin-top: 0.1rem;
        box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);

        &:before {
          content: "";
          border: solid;
          border-color: transparent transparent #ffffff transparent;
          border-width: 0px 6px 6px 6px;
          left: 0.1rem;
          top: 0;
          position: absolute;
          margin-top: -6px;
        }

        li {
          height: 0.5rem;
          box-sizing: border-box;
          width: 50%;
          font-size: 0.28rem;
          white-space: nowrap;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          padding-left: 0.1rem;

          img {
            height: 0.25rem;
          }
        }
      }
    }

    .sign {
      padding-right: 0.25rem;

      .iconfont {
        font-size: 0.5rem;
        vertical-align: middle;
        margin-right: 0.2rem;
        color: #ffed40;
      }

      span {
        vertical-align: middle;
      }

      .avatar {
        width: 0.6rem;
        vertical-align: middle;
        margin-right: 0.1rem;
      }

      .icon-gold {
        width: 0.32rem;
        vertical-align: middle;
      }

      a {
        font-size: 0.28rem;
        border: 0;

        &:last-child {
          margin-right: 0;
          border-left: 2px solid #ffffff;
          padding-left: 0.25rem;
          clip-path: polygon(0 0.1rem, 100% 0.1rem, 100% 100%, 0 0.3725rem);
        }
      }
    }

    #service {
      position: absolute;
      right: 0.3rem;
      top: 0.225rem;

      .iconfont {
        color: #ffffff;
        font-size: 0.4rem;
      }
    }
  }

  &.template-2 {
    .dropdown {
      position: relative;
      margin-left: 0.15rem;

      h4 {
        font-weight: normal;
        font-size: 0.24rem;
        text-transform: uppercase;
        display: flex;

        img {
          height: 0.25rem;
        }

        .icon-back {
          font-size: 0.22rem;
          display: inline-block;
          transform: rotateZ(270deg);
        }
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        list-style: none;
        min-width: 4rem;
        background-color: #ffffff;
        color: #333333;

        li {
          height: 0.5rem;
          box-sizing: border-box;
          width: 50%;
          font-size: 0.32rem;
          white-space: nowrap;
          display: flex;
          align-items: center;

          img {
            height: 0.25rem;
          }
        }
      }
    }

    .sign {
      line-height: normal;
    }

    .inner {
      background-color: $TEMPLATE2-theme-color;

      &.transparent {
        background-color: transparent;
      }

      &.covered {
        &:after {
          content: "";
        }
      }

      &:after {
        content: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.2);
      }

      > .left {
        width: 25%;
      }
      > h1 {
        width: 50%;
      }
      > .right {
        width: 25%;
        // flex-grow: 1;

        &.expand {
          width: auto;
        }
      }
    }

    hgroup {
      // position: absolute;
      margin-right: 0.15rem;
      top: 0.125rem;

      h6 {
        font-size: 0.24rem;
        font-weight: normal;

        .iconfont,
        .iconfont2 {
          font-size: 0.28rem;

          &:first-child {
            margin-right: 0.075rem;
          }
        }
      }
    }

    nav.header-menu {
      + #service {
        right: 0.8rem;
      }

      ul {
        top: 0.9rem;

        &:before {
          right: 0.15rem;
        }
      }
    }

    #service {
      // position: absolute;
      right: 0.3rem;
      top: 0.225rem;
      margin-right: 0.15rem;

      .iconfont {
        color: #ffffff;
        font-size: 0.4rem;
      }
    }

    nav.title-menu {
      background: $TEMPLATE2-theme-color;

      ul {
        li {
          width: 48%;
          margin-right: 4%;

          &:nth-child(2n + 2) {
            margin-right: 0;
          }

          &.highlight {
            a {
              color: #ffffff;
              border-color: #ffffff;
            }
          }

          a {
            font-size: 0.3rem;
            color: #ffffff;
            border-width: 1.5px;
            border-color: #ffffff;
            padding: 0.15rem 0.33rem;
          }
        }
      }
    }

    h1 {
      text-align: center;
      font-size: 0.36rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      flex-direction: column;

      &.logo {
        flex-direction: row;

        span {
          height: 0.68rem;

          img {
            max-height: 100%;
          }
        }
      }

      #title-menu-switcher {
        transform: rotate(270deg);
        display: inline-block;
        font-weight: 400;
        transition: 0.2s ease-out all;
        transform-origin: center center;

        &.active {
          transform: rotate(90deg);
        }
      }

      span {
        display: block;
        font-size: 0.24rem;
        font-weight: 400;
      }
    }
  }
  &.template-1 {
    .left,
    .right {
      position: absolute;
      top: 10px;
      i {
        font-size: 0.3rem;
      }
    }
    .right {
      right: 10px;
    }
  }
  .inner {
    display: flex;
    align-items: center;
    width: 100%;
    height: 0.88rem;
    background-color: #3985e1;
    position: relative;
    z-index: 11;

    &.transparent {
      background-color: transparent;
    }

    .left {
      width: 25%;

      &.collapse {
        width: auto;
      }
    }

    h1 {
      width: 100%;
    }

    .right {
      display: flex;
      justify-content: flex-end;

      &.expand {
        width: 25%;
      }
    }
  }

  button#history-back {
    position: absolute;
    left: 0.15rem;
    top: 0;
    height: 100%;
    background: 0;
    border: 0;
    color: #ffffff;
    font-size: 0.32rem;
    font-weight: 500;

    i {
      font-size: 0.3rem;
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
    }
  }

  h1 {
    text-align: center;
    font-size: 0.36rem;
    font-weight: 500;
    display: table-cell;
    vertical-align: middle;

    #title-menu-switcher {
      transform: rotate(270deg) translateX(2px);
      display: inline-block;
      font-weight: 400;
      transition: 0.2s ease-out all;
      transform-origin: center center;

      &.active {
        transform: rotate(90deg);
      }
    }

    .sub-title {
      display: block;
      font-size: 0.24rem;
      font-weight: 400;
    }
  }

  .sign {
    text-align: right;
    line-height: 0.88rem;
    white-space: nowrap;

    a {
      font-size: 0.3rem;
      color: #ffffff;
      border: 1px solid #ffffff;
      padding: 0.05rem 0.1rem;
      margin-right: 0.25rem;
    }
  }

  nav.header-menu {
    top: 0;
    right: 0.05rem;
    height: 100%;

    #header-menu-switcher {
      font-size: 0.4rem;
      width: 0.8rem;
      height: 100%;
      display: table;
      position: relative;
      text-align: center;
      z-index: 10;

      &:before {
        display: table-cell;
        vertical-align: middle;
      }
    }

    ul {
      position: absolute;
      right: 0.1rem;
      border-radius: 0 0 6px 6px;
      box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.14);
      background-color: #ffffff;
      z-index: 10;
      list-style: none;
      padding: 0.125rem 0.33rem;

      &:before {
        content: "";
        border: solid;
        border-color: #ffffff transparent;
        border-width: 0px 6px 6px 6px;
        top: -6px;
        right: 10%;
        position: absolute;
      }

      li {
        white-space: nowrap;

        &:last-child {
          a {
            border-bottom: 0;
          }
        }

        a {
          color: #333333;
          font-size: 0.3rem;
          padding: 0.15rem 0.25rem 0.15rem 0;
          border-bottom: 1px solid #d9d9d9;
          display: flex;
          align-items: center;

          i {
            font-size: 0.4rem;
            margin-right: 0.15rem;

            &:before {
              vertical-align: middle;
            }
          }
        }
      }
    }
  }

  nav.title-menu {
    position: absolute;
    top: 0.88rem;
    left: 0;
    width: 100%;
    z-index: 10;
    background: #ffffff;
    color: #999999;
    padding: 0.25rem 0.25rem 1rem;
    box-sizing: border-box;

    ul {
      list-style: none;
      margin-top: 0.25rem;

      li {
        text-align: center;
        width: 30%;
        float: left;
        margin-right: 3vw;
        margin-bottom: 3vw;

        &.highlight {
          background-color: rgba(57, 133, 225, 0.2);

          a {
            color: #3985e1;
            border-color: #3985e1;
          }
        }

        a {
          color: #666666;
          font-size: 0.26rem;
          padding: 0.1rem 0.33rem;
          border-radius: 3px;
          display: block;
          border: 1px solid #cccccc;

          &.highlight,
          &:active {
            color: #3985e1;
            border: 1px solid #3985e1;
            background-color: rgba(57, 133, 225, 0.2);
          }
        }
      }
    }
  }

  .viewport-overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 9;
    display: none;

    &.active {
      display: block;
    }
  }
}
</style>